.EsculturaContainer {
    margin: auto;
}

.EsculturasGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-column-gap: 10px;
    grid-row-gap: 50px;
}

.EsculturaItem {
    margin: auto;
    width: 300px;
    height: 300px;
}

.EsculturaItem > div {
    width: 100%;
    height: 100%;
}

.EsculturaItem img {
    width: 100%;
    height: 100%;
    object-fit: contain;

}

.labeltopContainer{
    margin-top: 130px;
    @include media-breakpoint-up(md) { 
        margin-top: 0px;
    }
}

