body {
    background-color: #FCFBF9;
}

.Obra {
    animation: fade-in 2s;
    text-align: center;
}

.Serie {
    font-size: 32px;
}

.Serie>a:hover {
    background-color: gray;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.Categoria {
    @include media-breakpoint-up(md) { 
        position: absolute;
    }
    
    text-transform: uppercase;
    font-size: 2.3rem;
    // background-color: greenyellow;
    height: 100px;
    width: 100px;
}

.instal {
    @include media-breakpoint-up(md) { 
        top: 35%;
        left: 20%;
    }
}

.pintura{
    top:10%;
    left: 60%;
}

.escultura{
    @include media-breakpoint-up(md) { 
    top: 80%;
    left: 40%;
    }
}

.Categoria p {
    position: absolute;
    z-index: 100;
    @include media-breakpoint-up(md) { 
        opacity: 0;
    }
    transition: opacity 500ms;
}

.Categoria:hover p {
    opacity: 1;
    
}

.Categoria span {
    @include media-breakpoint-up(md) { 
        color: white;
    }
}

.Dot {
    @include media-breakpoint-up(md) { 
        width: 200px;
        height: 200px;
        position: absolute;
        z-index: 0;
        display: inline-block;
    }
    display: none;
}

.Instalación {
    @include media-breakpoint-up(md) { 
        top: -40px;
        left: -70px;
    }
}


.Pintura {
    @include media-breakpoint-up(md) { 
        left: 45px;
        top: -36px;
    }
}

.Escultura {
    @include media-breakpoint-up(md) { 
        left: 90px;
        top: -36px;
    }
}
