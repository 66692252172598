@font-face {
    font-family: leonorFont;
    src: url('./fonts/MonacoRegular.ttf');
}

body, html {
    font-family: leonorFont, sans-serif;
    font-size: 100%;
    height: 100%;
    margin: 0;
    width: 100%;
    animation: fade-in 1s;
}

.BackgroundImage {
    background-image: url('./img/fondo_pagina_principal.jpg');
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    animation: fade-in 2s;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


header {
    height: 137px;
    position: relative;
    z-index: 100;
}

a:link {
    text-decoration: inherit;
    color: inherit;
    /*noinspection CssInvalidPropertyValue*/
    cursor: hover;
}

a:visited {
    text-decoration: inherit;
    color: inherit;
    /*noinspection CssInvalidPropertyValue*/
    cursor: hover;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.App {
    margin: auto;
    padding: 1rem;
    max-width: 1200px;
}

.Nav {
    position: relative;
    width: 100%;
    height: 100px;
}

.flex-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @include media-breakpoint-up(md) { 
        flex-direction: row;
        padding: 30px;

    }
    padding-top: 20px;
}

.flex-container > div {
    margin: 5px;
    border-radius: 3px;
}

.flex-container2 {
    display: flex;
    flex-direction: row;
    @include media-breakpoint-up(md) { 
        padding: 0;        
    }
    padding-top:40px;
    justify-content: space-between;
}

.flex-container2 > div {
    margin: auto 50px;
    border-radius: 3px;
}

.NavTitle {
    text-transform: uppercase;
    color: black;
    font-size: 2.7rem;
}

.HorizontalBar {
    @include media-breakpoint-up(md) { 
        width: 1000px;
    }
    width: 250px;
    height: 0;
    border: 2px solid black;
    position: absolute;
    top: 56px;
    left: 70px;
}

.NavCircle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: black;
    transition: width 500ms, height 500ms
}

.wrapper:hover .NavLink {
   
    opacity: 1;
}

.NavLink {
    @include media-breakpoint-up(md) { 
        opacity: 0;
        
    }
    text-align: center;
    color: black;
    transition: opacity 500ms;
    position: absolute;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100px;
    height: 100px;
}

.NavLink > div {
    line-height: 90px;
}

.NavLine {
        border: 2px solid white;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 100%;
        z-index: -1;
}

.mainContent {
    position: relative;
}

.Social {
    position: absolute;
    top: 10px;
    right: 0;
    background-color: white;
    opacity: 0.7;
    border-radius: 25px;
}

.Social > a {
    margin: auto 10px;
}

.pdfLink{
    color: rgb(31, 105, 216) !important;
    text-decoration: underline !important;
}

.videoOverlay > div {
    z-index: 10000;
}