.Oxidada {
    @include media-breakpoint-up(md) { 
        position: absolute;
    }
}

.Gota {
    @include media-breakpoint-up(md) { 
        position: absolute;
    }
}

.Lagrima {
    @include media-breakpoint-up(md) { 
        position: absolute;
    }
}

.Negra {
    @include media-breakpoint-up(md) { 
        position: absolute;
    }
}

.TextosGrid {
    display: grid;
    justify-content: center;
    margin-top: 130px;
    @include media-breakpoint-up(md) { 
        grid-template-columns: auto auto;
        margin-top: 0px;
    }
    grid-column-gap: 30px;
    grid-row-gap: 20px;
}

.TextoCover {
    width: 100%;
    @include media-breakpoint-up(md) { 
        width: 200px;
    }
    /*height: 100px;*/
    /*background-color: red;*/
    border-radius: 25%;
    padding: 0;
}

.TextoCover:hover {
    cursor: pointer;
}


.LabelContainer {
    height: 70px;
}

.Label {
    height: inherit;
}

.GalleryContaineraa {
    margin: auto;
    @include media-breakpoint-up(md) { 
        width: 70%;
    }
}

.image-gallery {
    @include media-breakpoint-up(md) { 
        margin: 30px auto;
    }
}

.image-gallery-image img {
    @include media-breakpoint-up(md) { 
        height: 400px;
    }
    height:250px;
    object-fit: contain;
}


.image-gallery-description {
    opacity: 0;
}

.image-gallery:hover .image-gallery-description {
    opacity: 1;
}

.galleryTop{
    margin-top: 130px;
    @include media-breakpoint-up(md) { 
    margin-top: 0px;
    }
}

.image-gallery.fullscreen-modal{
    z-index: 10000 !important;
}


.LanguageSelector {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* center items vertically, in this case */
}

.LanguageButton {
    position: static;
    margin: 100px 100px;
    height: auto;
    background: none;
    border: none;
    border-radius: 0;
    font-family: leonorFont, sans-serif;
    @include media-breakpoint-up(md) { 
        font-size: 2rem;
    }
    font-size: 1.5rem;
    text-transform: uppercase;
}

.LanguageButton:hover {
    cursor: pointer;
    color: white;
    background-color: black;
}
