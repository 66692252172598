.Contacto {
    background-color: white;
    box-shadow: 5px 5px 10px black;
    position: relative;
    display: flex;
    margin-top: 130px;
    flex-direction: column;
    @include media-breakpoint-up(md) { 
    margin-top: 0px;
    flex-direction: row;
    }
}

.ContactoBackgroundImage {
    @include media-breakpoint-up(md) { 
        width: 500px;
        max-height: 500px;
        object-fit: scale-down;
    }
}

.ContactoForm {
    display: inline-block;
    @include media-breakpoint-up(md) { 
        width: 100%;
    }
    padding: 10px;
}

label {
    font-size: 0.8rem;
}

input, textarea {
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid black;
    display: block;
    width: 100%;
}

button {
    border-radius: 15px;
    width: 150px;
    height: 25px;
    background-color: #c9c9c9;
    font-weight: bold;
    @include media-breakpoint-up(md) { 
        position: absolute;
        right: 10px;
    }
}
