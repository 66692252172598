.Instalacion {
    align-content: center;
}


.InstalacionType {
    margin: 20px auto;
    /* width: 500px; */
    text-align: center;
    text-transform: uppercase;
}

.instalContainer{
    margin-top: 130px;
    @include media-breakpoint-up(md) { 
        margin-top: 0px;
    }
}