.Bio {
    display: grid;
    place-items: center;
    column-gap: 20px;
    row-gap: 20px;
    grid-template: auto auto;
    margin-top: 150px;
    @include media-breakpoint-up(md) { 
        grid-template-columns: auto auto auto;
        margin-top: 0px;
    }
}


.BioItem {
    width: 100%;
    background-color: #e5e5e5;
    position: relative;
    text-align: center;
    height: 0;
    padding-bottom: 100%;
    font-size: 2rem;
    background-repeat: no-repeat;
    background-size: cover;
    transition: background-color 500ms;
}

.BioItem:hover {
    background-color: black;
    cursor: pointer;
}

.BioItem > div {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    transition: color 500ms;
}

.BioItem:hover > div {
    color: white;
}

.ModalTitle {
    display: inline-block;
    margin: 2px auto 17px auto;
}


.CloseButton {
    position: absolute;
    right: 20px;
}

.CloseButton:hover {
    cursor: pointer;
}